import React, { useContext, useEffect, useState } from 'react';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { DepartmentPath } from '../../interfaces';
import { InfoModal } from './InfoModal';

export const DepartmentPathFilterTab: React.FC = () => {
  const { departmentPaths, filteredOutDepartmentPathIdsRef } = useContext(
    DepartmentPathContext
  );
  const [data, setData] = useState<DepartmentPath | null>(null);

  useEffect(() => {
    return () => {
      filteredOutDepartmentPathIdsRef.current = [];
    };
  }, []);

  return (
    <>
      <div className="p flex flex-col gap-y-2">
        {departmentPaths.map((path) => (
          <div
            key={path.fields.departmentPathId}
            onClick={() => setData(path)}
            className="department-path-box"
          >
            <img
              className="path-image"
              style={{ background: path.fields.departmentPathColor }}
              src={path.fields.departmentPathIcon.fields.file.url}
              alt={'Office'}
            />
            <span className="path-box-text">
              {path.fields.departmentPathName}
            </span>
          </div>
        ))}
      </div>
      {data && (
        <InfoModal
          color={data.fields.departmentPathColor}
          description={data.fields.departmentPathDescription}
          id={data.sys.id}
          image={data.fields.departmentPathImage?.fields.file.url}
          imageDescription={data.fields.departmentPathImage?.fields.description}
          isRoleNode={false}
          icon={data.fields.departmentPathIcon?.fields.file.url}
          departmentRoleFrom={data.fields.departmentPathName}
          onClose={() => setData(null)}
        />
      )}
    </>
  );
};
