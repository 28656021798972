import React, { memo, useEffect, useState } from 'react';
import { PathNode, PathNodeData } from '../../interfaces';
import { PathNodeHandles } from './PathNodeHandles';
import { InfoModal } from './InfoModal';
import { usePaths } from './usePaths';
import { Handle, Position, useReactFlow } from 'reactflow';
const mainNodeImg = require('../../assets//images/main-node.svg');
const lidlPlantImg = require('../../assets/images/lidl-plant.svg');
const infiniteImg = require('../../assets/images/infinite.svg');

interface Props {
  data: PathNodeData;
}

const PathNode: React.FC<Props> = ({ data }) => {
  const [isSelected, setIsSelected] = useState(false);
  const { updateOpacityOfUnselectedNodes, updateOpacityOfUnselectedEdges } =
    usePaths();
  const mainNodeId = 'main';
  const backgroundNodeId = 'background';
  const isMainNode = data.id === mainNodeId;
  const isBackgroundNode = data.id === backgroundNodeId;
  const { setCenter } = useReactFlow();

  const handleNodeClick = () => {
    if (!data.isDisabled && !isMainNode && !isBackgroundNode) {
      setIsSelected(true);
      updateOpacityOfUnselectedNodes(data.id, false, !data.isRoleNode);
      updateOpacityOfUnselectedEdges(data.id, false, !data.isRoleNode);
    }
  };

  const handlePopUpClose = () => {
    setIsSelected(false);
    updateOpacityOfUnselectedNodes(data.id, true);
    updateOpacityOfUnselectedEdges(data.id, true);
    const zoom = 1.5;
    const x = -180;
    const y = 300;
    setTimeout(() => {
      setCenter(x, y, { zoom, duration: 1000 });
    }, 200);
  };

  const nodeStyle = {
    border: isBackgroundNode || isMainNode ? '' : `1.5px solid ${data.color}`,
    backgroundColor: isBackgroundNode || isMainNode ? '' : data.color,
    outlineColor: isSelected ? `${data.color}50` : '',
    outlineStyle: isSelected ? 'solid' : '',
    outlineWidth: isSelected ? '8px' : '',
  };

  const animatedPathAndRolesLoading = () => {
    const nodes = document.querySelectorAll('.path-node');
    const roleTexts = document.querySelectorAll('.role-text') as any;
    let index = 0;
    nodes.forEach((node: any) => {
      node.style.opacity = 0;
      const delay = Math.random() * 1;
      roleTexts[index].style.opacity = `0`;
      node.style.animation = `pop 0.5s ${delay + 1}s ease-in-out forwards`;
      roleTexts[index].style.animation = `fadeInWithGradient 0.5s ${
        delay + 1
      }s ease-in-out forwards`;
      index++;
    });
  };

  useEffect(() => {
    animatedPathAndRolesLoading();
  }, []);

  return (
    <div className="relative">
      <button
        type="button"
        style={isMainNode || isBackgroundNode ? {} : nodeStyle}
        className={`${
          isMainNode
            ? 'disable-focus-visible cursor-default'
            : isBackgroundNode
            ? 'background-node disable-focus-visible cursor-default'
            : 'path-node'
        }`}
        aria-describedby={data.isRoleNode ? `node-name-${data.id}` : ''}
        disabled={isSelected || data.isDisabled}
        onClick={() => handleNodeClick()}
      >
        {isMainNode ? (
          <div className="relative">
            <img
              src={mainNodeImg}
              alt={data.departmentRoleFrom}
              className="main-node pointer-events-none"
            />
            <div className="main-center-node">
              <div className="growth-animation">
                <img src={lidlPlantImg} alt="lidl-plant" />
              </div>
            </div>
          </div>
        ) : isBackgroundNode ? (
          <img
            className="background-node"
            src={infiniteImg}
            alt={data.departmentRoleFrom}
          />
        ) : (
          <img
            src={data.icon}
            alt={data.departmentRoleFrom}
            className="role-node pointer-events-none"
          />
        )}
      </button>
      <div
        id={`node-name-${data.id}`}
        className="role-text role-title-text absolute w-75 cursor-default"
      >
        {data.departmentRoleFrom}
        {isMainNode ? null : (
          <>
            {!data.departmentRoleTo ? null : (
              <div className="flex flex-col">
                <span className="text-8 font-normal">to</span>
                <span>{data.departmentRoleTo}</span>
              </div>
            )}
          </>
        )}
      </div>

      {isMainNode ? (
        <>
          <Handle
            type="source"
            className="top-dot"
            position={Position.Top}
            id="top"
          />
          <Handle
            type="source"
            className="right-dot"
            position={Position.Right}
            id="right"
          />
          <Handle
            type="source"
            className="bottom-dot"
            position={Position.Bottom}
            id="bottom"
          />
          <Handle
            type="source"
            className="left-dot"
            position={Position.Left}
            id="left"
          />
        </>
      ) : null}

      <PathNodeHandles data={data} />

      {isSelected && (
        <InfoModal
          color={data.color}
          description={data.description}
          icon={data.icon}
          id={data.id}
          image={data.image.url}
          imageDescription={data.image.description}
          isRoleNode={data.isRoleNode}
          departmentRoleFrom={data.departmentRoleFrom}
          departmentRoleTo={data.departmentRoleTo}
          onClose={() => handlePopUpClose()}
        />
      )}
    </div>
  );
};

export default memo(PathNode);
