import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { RoleDetails } from './components/role-details/RoleDetails';
import { DevelopmentProgramDetails } from './components/development-programs/DevelopmentProgramDetails';
import { ErrorPage } from './components/error/ErrorPage';

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/development-programs"
        element={<Home defaultDisplayContent="DevelopmentPrograms" />}
      />
      <Route path="/:departmentRoleId" element={<RoleDetails />} />
      <Route
        path="/development-programs/:developmentProgramId"
        element={<DevelopmentProgramDetails />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
