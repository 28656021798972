import React from 'react';
import { TrainingAndDevelopment } from '../../interfaces';
import { PlaceholderIcon } from '../icons';
import { CTALink } from '../elements/CTALink';
import sanitizeHtml from 'sanitize-html';
import { marked } from 'marked';
import parse from 'html-react-parser';

interface Props {
  trainingAndDevelopment?: TrainingAndDevelopment;
}

export const TrainingDevelopment: React.FC<Props> = ({
  trainingAndDevelopment,
}) => {
  const fields = trainingAndDevelopment?.fields;

  return trainingAndDevelopment ? (
    <section className="my-32 flex space-x-28 px-20">
      <div className="w-1/2">
        <h2 className="mb-4 flex flex-col text-40 leading-normal text-black">
          <span>Trainings &</span> <b>Development</b>
        </h2>
        <p className="text-18 text-grey-text">
          We invest in your growth with comprehensive resources and cutting-edge
          tools, empowering you to thrive.
        </p>
        <br />
        <p className="mb-12 text-18 text-grey-text">
          {fields.trainingAndDevelopmentDescription}
        </p>
        {fields.trainingAndDevelopmentLink && (
          <CTALink
            link={fields.trainingAndDevelopmentLink}
            text="Explore Courses"
          />
        )}
      </div>

      <div className="w-1/2">
        {fields.trainings.map((training) => {
          const trainingFields = training.fields;

          return trainingFields.trainingDescription ? (
            <details key={training.sys.id} className="role-training-card">
              <summary className="flex cursor-pointer justify-between">
                <div className="flex items-center space-x-6">
                  {trainingFields.trainingIcon ? (
                    <img
                      src={`https:${trainingFields.trainingIcon.fields.file.url}`}
                      alt=""
                      className="role-training-card-icon"
                    />
                  ) : (
                    <PlaceholderIcon />
                  )}
                  <div>
                    <p className="mb-1 text-14 font-semibold text-primary-medium">
                      {trainingFields.trainingLabel}
                    </p>
                    <p className="text-20 font-semibold text-black">
                      {trainingFields.trainingTitle}
                    </p>
                  </div>
                </div>
              </summary>
              <p className="pl-16 pt-2 text-14 text-grey-text">
                {parse(
                  sanitizeHtml(marked(trainingFields.trainingDescription))
                )}
              </p>
            </details>
          ) : (
            <div key={training.sys.id} className="role-training-card space-x-6">
              {trainingFields.trainingIcon ? (
                <img
                  src={`https:${trainingFields.trainingIcon.fields.file.url}`}
                  alt=""
                  className="role-training-card-icon"
                />
              ) : (
                <PlaceholderIcon />
              )}
              <div>
                <p className="mb-1 text-14 font-semibold text-primary-medium">
                  {trainingFields.trainingLabel}
                </p>
                <p className="text-20 font-semibold text-black">
                  {trainingFields.trainingTitle}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  ) : null;
};
