export enum ContentTypes {
  DepartmentPath = 'departmentPath',
  DepartmenthRole = 'departmentRole',
  DevelopmentProgram = 'developmentProgramDetails',
}

export enum MetaDataTags {
  NestedPath = 'nestedPath',
}

interface Sys {
  id: string;
  type: string;
  linkType: string;
}

export interface MetaData {
  tags: {
    sys: Sys;
  }[];
}

interface ContentfulEntry {
  metadata: MetaData;
  sys: {
    space: {
      sys: Sys;
    };
    id: string;
    type: string;
    createdAt: string;
    updatedAt: string;
    environment: {
      sys: Sys;
    };
    revision: number;
    contentType: {
      sys: {
        type: string;
        linkType: string;
        id: ContentTypes;
      };
    };
    locale: string;
  };
}

export interface ContentfulMedia extends ContentfulEntry {
  fields: {
    title: string;
    description: string;
    file: {
      url: string;
      details: {
        size: number;
        image?: {
          width: number;
          height: number;
        };
      };
      fileName: string;
      contentType: string;
    };
  };
}

export interface EssentialFunction extends ContentfulEntry {
  fields: {
    title: string;
    description: string;
    icon?: ContentfulMedia;
  };
}

export interface Competency extends ContentfulEntry {
  fields: {
    title: string;
    description: string;
    icon?: ContentfulMedia;
  };
}

export interface Competencies extends ContentfulEntry {
  fields: {
    competencyId: string;
    list: Competency[];
    description?: string;
    image?: ContentfulMedia;
  };
}

interface Training extends ContentfulEntry {
  fields: {
    trainingTitle: string;
    trainingLabel: string;
    trainingDescription?: string;
    trainingIcon?: ContentfulMedia;
  };
}

export interface TrainingAndDevelopment extends ContentfulEntry {
  fields: {
    trainingAndDevelopmentId: string;
    trainingAndDevelopmentDescription: string;
    trainings: Training[];
    trainingAndDevelopmentLink?: string;
  };
}

export interface Testimonial extends ContentfulEntry {
  fields: {
    testimonialId: string;
    testimonialQuote?: string;
    video?: ContentfulMedia;
    image?: ContentfulMedia;
    employeeName?: string;
    employeeRole?: string;
  };
}

export interface Module extends ContentfulEntry {
  fields: {
    title: string;
    format: string;
    description: string;
  };
}

export interface Modules extends ContentfulEntry {
  fields: {
    modulesId: string;
    list: Module[];
    description?: string;
    image?: ContentfulMedia;
  };
}

export interface FAQ extends ContentfulEntry {
  fields: {
    faqTitle: string;
    faqDetails: string;
  };
}

export interface DepartmentRole extends ContentfulEntry {
  fields: {
    departmentRoleId: string;
    departmentRoleFrom: string;
    departmentRoleTo: string;
    departmentRoleDescription: string;
    departmentRoleLongDescription: string;
    departmentRoleImage?: ContentfulMedia;
    level?: number;
    jobListingLink?: string;
    essentialFunctions?: EssentialFunction[];
    competencies?: Competencies;
    trainingAndDevelopment?: TrainingAndDevelopment;
    testimonial?: Testimonial;
    similarRoles?: DepartmentRole[];
    faqs?: FAQ[];
    openFaqs?: boolean;
    skipPath?: DepartmentRole;
  };
}

export type DepartmentRoleOrPathArray = Array<DepartmentRole>;

export interface DepartmentPath extends ContentfulEntry {
  fields: {
    departmentPathColor: string;
    departmentPathDescription: string;
    departmentPathId: number;
    departmentPathIcon: ContentfulMedia;
    departmentRoleIcon: ContentfulMedia;
    departmentRoleTo?: string;
    departmentPathImage: ContentfulMedia;
    departmentPathName: string;
    departmentPathRoles: DepartmentRoleOrPathArray;
  };
}

export interface DevelopmentProgram extends ContentfulEntry {
  fields: {
    developmentProgramTitle: string;
    developmentProgramDescription: string;
    developmentProgramColor: string;
    modules: Modules;
    developmentProgramTargetAudience?: string;
    developmentProgramTiming?: string;
    developmentProgramImage?: ContentfulMedia;
    competencies?: Competencies;
    testimonial?: Testimonial;
    faqs?: FAQ[];
    openFaqs?: boolean;
  };
}
