import * as contentful from 'contentful';
import { ContentTypes, MetaData, MetaDataTags } from '../interfaces';

const contentfulConfig = {
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
};

const contentfulClient = contentful.createClient(contentfulConfig);

export const getContentfulEntry = async (entryId: string) => {
  const entry = await contentfulClient.getEntry(entryId, { include: 2 });

  return entry;
};

export const checkIfNestedDepartmentPath = (tags: MetaData['tags']) => {
  return (
    tags.filter((tag) => {
      return tag.sys.id === MetaDataTags.NestedPath;
    }).length > 0
  );
};

export const getContentfulEntries = async (contentTypeId?: ContentTypes) => {
  const entries = await contentfulClient
    .getEntries({
      content_type: contentTypeId,
      include: 2,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  if (contentTypeId === ContentTypes.DepartmentPath) {
    const rootDepartmentPaths = entries.items.filter((entry) => {
      const isNestedDepartmentPath = checkIfNestedDepartmentPath(
        entry.metadata.tags
      );

      return !isNestedDepartmentPath;
    });

    return rootDepartmentPaths;
  }

  return entries.items;
};

export const getDepartmentPaths = async () =>
  await getContentfulEntries(ContentTypes.DepartmentPath);
export const getDepartmentRoles = async () =>
  await getContentfulEntries(ContentTypes.DepartmenthRole);
export const getDevelopmentPrograms = async () =>
  await getContentfulEntries(ContentTypes.DevelopmentProgram);
