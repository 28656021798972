import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DevelopmentProgram } from '../../interfaces';

interface Props {
  program: DevelopmentProgram;
}

export const ProgramCard: React.FC<Props> = ({ program }) => {
  const [isHovered, setIsHovered] = useState(false);
  const fields = program.fields;
  const backgroundColorOpacity = isHovered ? '99' : '80';
  const imageURL = fields.developmentProgramImage
    ? `https:${fields.developmentProgramImage.fields.file.url}`
    : null;
  const backgroundImage = imageURL
    ? `linear-gradient(180deg, ${fields.developmentProgramColor}${backgroundColorOpacity} 66%, ${fields.developmentProgramColor} 100%), url(${imageURL})`
    : null;

  return (
    <Link
      to={`/development-programs/${program.sys.id}`}
      key={program.sys.id}
      className="flex flex-col justify-end rounded-2xl bg-cover bg-center bg-no-repeat p-8"
      style={{
        backgroundColor: fields.developmentProgramColor,
        backgroundImage: backgroundImage,
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <h2 className="text-24 font-bold text-white">
        {fields.developmentProgramTitle}
      </h2>
      <p className="text-white">Development Program</p>
    </Link>
  );
};
