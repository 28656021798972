import React from 'react';

export const ErrorMessage: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <h2 className="mb-4 text-40 leading-tight text-black">
        <span>Oops!</span> <b>Something went wrong.</b>
      </h2>
      <p className="text-grey-text">
        We&apos;re sorry, something went wrong. Please try again.
      </p>
    </div>
  );
};
