import React from 'react';

interface Props {
  link: string;
  text: string;
  target?: string;
}

export const CTALink: React.FC<Props> = ({ link, text, target = '_blank' }) => {
  return (
    <a
      href={link}
      target={target}
      rel="noreferrer"
      className="inline-block bg-gradient-to-r from-primary-dark via-primary-medium to-primary-light px-8 py-4 text-24 font-semibold text-white"
    >
      {text}
    </a>
  );
};
