import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DepartmentRole } from '../../interfaces';
import { PlaceholderIcon } from '../icons';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { getDepartmentPathForRole } from '../../helpers';

interface Props {
  roles?: DepartmentRole[];
}

export const SimilarRoles: React.FC<Props> = ({ roles }) => {
  const { departmentPaths } = useContext(DepartmentPathContext);

  return roles ? (
    <section className="my-32 px-20">
      <h2 className="text-center text-40 leading-normal text-black">
        Explore <b>Similar Roles</b> in Other Areas
      </h2>
      <div className="mt-10 flex space-x-4">
        {roles.map((role) => {
          const fields = role.fields;
          const departmentPath = getDepartmentPathForRole(
            role.sys.id,
            departmentPaths
          );

          return (
            <Link
              key={role.sys.id}
              to={`/${role.sys.id}`}
              className="similar-role-card"
            >
              <div className="flex w-1/2 items-center justify-center rounded-l-2xl bg-grey-placeholder">
                {fields.departmentRoleImage ? (
                  <img
                    src={`https:${fields.departmentRoleImage.fields.file.url}`}
                    alt={fields.departmentRoleImage.fields.description || ''}
                    className="h-full w-full rounded-l-2xl object-cover"
                  />
                ) : (
                  <PlaceholderIcon />
                )}
              </div>
              <div className="w-1/2 p-8">
                {departmentPath &&
                  departmentPath.fields?.departmentPathIcon && (
                    <img
                      src={`https:${departmentPath.fields.departmentPathIcon.fields.file.url}`}
                      alt={departmentPath.fields.departmentPathName}
                      className="similar-role-path-icon mb-6"
                      style={{
                        border: `1.5px solid ${departmentPath.fields.departmentPathColor}}`,
                        backgroundColor:
                          departmentPath.fields.departmentPathColor,
                      }}
                    />
                  )}
                <h3 className="mb-3 text-20 font-semibold text-black">
                  {fields.departmentRoleTo}
                </h3>
                <p className="text-14 text-grey-text">
                  {fields.departmentRoleDescription}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  ) : null;
};
