import React from 'react';
import { Competency, EssentialFunction } from '../../interfaces';

interface Props {
  title: JSX.Element;
  responsibilities?: EssentialFunction[] | Competency[];
  displayBulletList?: boolean;
}

export const ResponsibilitiesSection: React.FC<Props> = ({
  title,
  responsibilities,
  displayBulletList = false,
}) => {
  return responsibilities ? (
    <section className="px-20 pb-32">
      <h2 className="mb-12 text-center text-40 text-white">{title}</h2>
      <div className="grid auto-cols-fr grid-flow-col gap-4">
        {responsibilities.map((responsibility) => {
          const fields = responsibility.fields;
          const list = displayBulletList ? fields.description.split('\n') : [];

          return (
            <div
              key={responsibility.sys.id}
              className="rounded-2xl bg-white p-10"
            >
              {fields.icon && (
                <img
                  src={`https:${fields.icon.fields.file.url}`}
                  alt=""
                  className="mb-8 h-16 rounded-lg bg-secondary-background30 p-4"
                />
              )}
              <h3 className="mb-3 text-20 font-semibold leading-normal">
                {fields.title}
              </h3>
              {displayBulletList ? (
                <ul>
                  {list.map((item) => (
                    <li key={item} className="text-14 text-grey-text">
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-14 text-grey-text">{fields.description}</p>
              )}
            </div>
          );
        })}
      </div>
    </section>
  ) : null;
};
