import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { getContentfulEntry } from '../../apis/cms';
import { DevelopmentProgram } from '../../interfaces';
import { CalendarIcon, TeamIcon } from '../icons';
import { ErrorPage } from '../error/ErrorPage';
import {
  FAQSection,
  Hero,
  ListDetailSection,
  ResponsibilitiesSection,
  Testimonial,
} from '../sections';

export const DevelopmentProgramDetails: React.FC = () => {
  const { developmentProgramId } = useParams();
  const [error, setError] = useState(false);
  const [developmentProgram, setDevelopmentProgram] =
    useState<DevelopmentProgram>(null);
  const { developmentPrograms } = useContext(DepartmentPathContext);

  useEffect(() => {
    const storedProgram = developmentPrograms.find(
      (role) => role.sys.id === developmentProgramId
    );

    if (storedProgram) {
      setDevelopmentProgram(storedProgram);
    } else {
      getContentfulEntry(developmentProgramId)
        .then((entry) => setDevelopmentProgram(entry as DevelopmentProgram))
        .catch(() => {
          setDevelopmentProgram(null);
          setError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (developmentProgram) {
      document.title =
        `${developmentProgram.fields.developmentProgramTitle} Development Program | Lidl US` ||
        'Lidl US';
    }
  }, [developmentProgram]);

  if (error) {
    return <ErrorPage />;
  }

  return developmentProgram ? (
    <div className="bg-grey-lightBackground">
      <div className="rounded-bl-60 bg-gradient-to-r from-primary-light via-primary-medium to-primary-dark">
        <div className="container-small">
          <Hero
            title={`${developmentProgram.fields.developmentProgramTitle} Development Program`}
            subtitle="Development Programs"
            returnPage="/development-programs"
            details={
              <div className="flex space-x-12 pt-2">
                {developmentProgram.fields.developmentProgramTargetAudience && (
                  <span className="mb-8 flex items-center">
                    <TeamIcon />
                    <p className="ml-3 text-14 font-semibold text-primary-medium">
                      {
                        developmentProgram.fields
                          .developmentProgramTargetAudience
                      }
                    </p>
                  </span>
                )}
                {developmentProgram.fields.developmentProgramTiming && (
                  <span className="mb-8 flex items-center">
                    <CalendarIcon />
                    <p className="ml-3 text-14 font-semibold text-primary-medium">
                      {developmentProgram.fields.developmentProgramTiming}
                    </p>
                  </span>
                )}
              </div>
            }
            description={
              developmentProgram.fields.developmentProgramDescription
            }
            returnText="Return to Home"
            imageUrl={
              developmentProgram.fields.developmentProgramImage?.fields.file.url
            }
            imageAlt={
              developmentProgram.fields.developmentProgramImage?.fields
                .description
            }
          />
          <ResponsibilitiesSection
            title={
              <>
                <b>Competencies Covered</b> in this Program
              </>
            }
            responsibilities={
              developmentProgram.fields.competencies?.fields.list
            }
            displayBulletList={true}
          />
          <ListDetailSection
            title={
              <>
                Program <b>Modules</b>
              </>
            }
            content={developmentProgram.fields.modules}
            displayNumber={true}
          />
        </div>
      </div>
      <div className="container-small">
        <Testimonial testimonial={developmentProgram.fields.testimonial} />
        <FAQSection
          faqs={developmentProgram.fields.faqs}
          isOpen={developmentProgram.fields.openFaqs}
        />
      </div>
    </div>
  ) : null;
};
