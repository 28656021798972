import React, { Component, ReactNode } from 'react';
import { ErrorPage } from './ErrorPage';

interface Props {
  isFullScreenError: boolean;
  showErrorHero?: boolean;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
