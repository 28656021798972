import React from 'react';
import { Link } from 'react-router-dom';
import { LidlIcon, PlaceholderIcon } from '../icons';
import { CTALink } from '../elements/CTALink';

export interface Props {
  title: string;
  description: string;
  returnText: string;
  subtitle?: string;
  details?: JSX.Element;
  link?: string;
  imageUrl?: string;
  imageAlt?: string;
  returnPage?: string;
}

export const Hero: React.FC<Props> = ({
  title,
  description,
  returnText,
  subtitle,
  details,
  link,
  imageUrl,
  imageAlt,
  returnPage,
}) => {
  return (
    <section className="hero relative mb-32 rounded-bl-60 bg-secondary-background pb-24 pl-20 pt-16">
      <LidlIcon />
      <Link
        to={returnPage || '/'}
        className="mb-12 mt-8 inline-block text-black"
      >
        &larr; {returnText}
      </Link>
      {subtitle && (
        <p className="mb-4 text-20 font-semibold text-primary-medium">
          {subtitle}
        </p>
      )}
      <h1 className="mb-4 w-1/3 text-40 font-bold leading-normal text-black">
        {title}
      </h1>
      {details && <>{details}</>}
      <p className="mb-8 w-1/2 pr-32 text-18 text-black opacity-80">
        {description}
      </p>
      {link && <CTALink link={link} text="See opportunities" />}
      <div className="role-detais-hero-image">
        {imageUrl ? (
          <img src={`https:${imageUrl}`} alt={imageAlt || ''} loading="lazy" />
        ) : (
          <PlaceholderIcon />
        )}
      </div>
    </section>
  );
};
