import { DepartmentPath } from '../interfaces';

export const getDepartmentPathForRole = (
  departmentRoleId: string,
  departmentPaths: DepartmentPath[]
): DepartmentPath => {
  const path = departmentPaths.find((departmentPath) => {
    return departmentPath.fields.departmentPathRoles.find((role) => {
      return role.sys.id === departmentRoleId;
    });
  });

  return path;
};
