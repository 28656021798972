import React from 'react';

export const CalendarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1172_12413)">
      <path
        d="M17.6047 11.7998C14.4047 11.7998 11.8047 14.3998 11.8047 17.5998C11.8047 20.7998 14.4047 23.3998 17.6047 23.3998C20.8047 23.3998 23.4047 20.7998 23.4047 17.5998C23.4047 14.3998 20.8047 11.7998 17.6047 11.7998ZM17.6047 22.1998C15.0847 22.1998 13.0047 20.1198 13.0047 17.5998C13.0047 15.0798 15.0847 12.9998 17.6047 12.9998C20.1247 12.9998 22.2047 15.0798 22.2047 17.5998C22.2047 20.1198 20.1247 22.1998 17.6047 22.1998Z"
        fill="#0050AA"
      />
      <path
        d="M19.04 17.76L18.2 17.28V14.2C18.2 14.08 18.12 14 18 14C17.44 14 17 14.44 17 15V17.52C17 17.8 17.16 18.08 17.4 18.2L19.12 19.2C19.2 19.24 19.32 19.24 19.4 19.12C19.68 18.68 19.52 18.04 19.04 17.76Z"
        fill="#0050AA"
      />
      <path
        d="M10.2016 17.3996H5.20156C3.32156 17.3996 1.80156 15.8796 1.80156 13.9996V6.59961H16.6016V10.1996C16.6016 10.3196 16.6816 10.3996 16.8016 10.3996C17.3616 10.3996 17.8016 9.95961 17.8016 9.39961V2.99961C17.8016 2.55961 17.4416 2.19961 17.0016 2.19961H15.1616V1.39961C15.1616 0.959609 14.8016 0.599609 14.3616 0.599609H12.2016C11.7616 0.599609 11.4016 0.959609 11.4016 1.39961V2.19961H7.00156V1.39961C7.00156 0.959609 6.64156 0.599609 6.20156 0.599609H4.04156C3.60156 0.599609 3.24156 0.959609 3.24156 1.39961V2.19961H1.40156C0.961562 2.19961 0.601562 2.55961 0.601562 2.99961V13.9996C0.601562 16.5596 2.64156 18.5996 5.20156 18.5996H9.40156C9.96156 18.5996 10.4016 18.1596 10.4016 17.5996C10.4016 17.4796 10.3216 17.3996 10.2016 17.3996ZM12.6016 1.79961H13.9616V3.27961H12.6016V1.79961ZM4.44156 1.79961H5.80156V3.27961H4.44156V1.79961ZM3.24156 3.39961V3.67961C3.24156 4.11961 3.60156 4.47961 4.04156 4.47961H6.20156C6.64156 4.47961 7.00156 4.11961 7.00156 3.67961V3.39961H11.4016V3.67961C11.4016 4.11961 11.7616 4.47961 12.2016 4.47961H14.3616C14.8016 4.47961 15.1616 4.11961 15.1616 3.67961V3.39961H16.6016V5.39961H1.80156V3.39961H3.24156Z"
        fill="#0050AA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1172_12413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
