import React, { Dispatch, SetStateAction } from 'react';
import { LidlIcon } from '../icons';
import { FilterTabButton } from './FilterTabButton';
import { DepartmentPathFilterTab } from '../department-paths/DepartmentPathFilterTab';
import { DevelopmentProgramTab } from '../development-programs/DevelopmentProgramTab';
import { useNavigate } from 'react-router-dom';

interface Props {
  displayTabContent: string;
  setDisplayTabContent: Dispatch<SetStateAction<string | undefined>>;
}

export const TabPanel: React.FC<Props> = ({
  displayTabContent,
  setDisplayTabContent,
}) => {
  const navigate = useNavigate();

  return (
    <div className="menu-background p-10 text-black">
      <div className="fade-in">
        <LidlIcon />
        <h1 className="mt-5 flex flex-col gap-4 leading-tight">
          <span className="text-24 text-white">#TeamLidlUS</span>
          <span className="text-40 font-bold text-white">Grow Your Career</span>
        </h1>
        <p className="text-18 text-white">
          We’re here to help you reach your career goals, one step at a
          time. Explore the roles and responsibilities available for you to grow
          into — across Lidl US.
        </p>

        <div className="tab mt-16">
          <FilterTabButton
            name="CAREERS PATHS"
            isActive={displayTabContent === 'Paths'}
            handleOnClick={() => {
              setDisplayTabContent('Paths');
              navigate('/');
            }}
          />
          <FilterTabButton
            name="DEVELOPMENT PROGRAMS"
            isActive={displayTabContent === 'DevelopmentPrograms'}
            handleOnClick={() => {
              setDisplayTabContent('DevelopmentPrograms');
              navigate('/development-programs');
            }}
          />
        </div>

        <div className="tab-content mt-8">
          {displayTabContent === 'Paths' && <DepartmentPathFilterTab />}
          {displayTabContent === 'DevelopmentPrograms' && (
            <DevelopmentProgramTab />
          )}
        </div>
      </div>
    </div>
  );
};
