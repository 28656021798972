import React from 'react';
import { Testimonial as TestimonialType } from '../../interfaces';
import { PlaceholderIcon, QuoteIcon } from '../icons';

interface Props {
  testimonial?: TestimonialType;
}

export const Testimonial: React.FC<Props> = ({ testimonial }) => {
  const fields = testimonial?.fields;

  return testimonial ? (
    <section className="testimonial-container w-full px-20 pb-12 pt-24">
      <div className="testimonial-media-container">
        {fields.video ? (
          <video controls className="testimonial-media">
            <source src={`https:${fields.video.fields.file.url}`} />
          </video>
        ) : (
          <>
            {fields.image ? (
              <img
                src={`https:${fields.image.fields.file.url}`}
                alt={fields.image.fields.description || ''}
                className="testimonial-media"
              />
            ) : (
              <div className="testimonial-media flex items-center justify-center bg-grey-placeholder">
                <PlaceholderIcon />
              </div>
            )}
          </>
        )}
      </div>
      <div className="testimonial-quote-container bg-gradient-to-r from-primary-dark via-primary-medium to-primary-light">
        <h2 className="mb-6 text-32 leading-normal text-white">
          What do <b>our employees think</b> about working at Lidl?
        </h2>
        <QuoteIcon />
        <p className="mt-2 text-20 font-semibold text-white">
          {fields.testimonialQuote}
        </p>
        <p className="mb-2 mt-8 text-20 font-semibold leading-normal text-white">
          {fields.employeeName}
        </p>
        <p className="text-16 font-semibold text-white opacity-50">
          {fields.employeeRole}
        </p>
      </div>
    </section>
  ) : null;
};
