import React from 'react';

export const PlaceholderIcon: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5 51.5C54.6046 51.5 55.5 50.6046 55.5 49.5V29.6L45 19.1L30.6 33.5L33.3 36.2C34.3 37.1 34.3 38.7 33.3 39.7C33.1 39.9 32.8 39.9 32.6 39.7L21 28.1L4.6 44.5C5.3 48.5 8.8 51.5 13 51.5H53.5ZM46.8 16.7L55.5 25.4V10.5C55.5 9.39543 54.6046 8.5 53.5 8.5H6.5C5.39543 8.5 4.5 9.39543 4.5 10.5V40.4L19.3 25.7C20.2 24.7 21.8 24.7 22.8 25.7L28.5 31.4L43.3 16.7C44.2 15.7 45.8 15.7 46.8 16.7ZM15.5 18C15.5 16.6 14.4 15.5 13 15.5C11.6 15.5 10.5 16.6 10.5 18C10.5 19.4 11.6 20.5 13 20.5C14.4 20.5 15.5 19.4 15.5 18Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};
