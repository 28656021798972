/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  FC,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { getDepartmentPaths, getDepartmentRoles } from '../apis/cms';
import {
  DepartmentPath,
  DepartmentRole,
  DevelopmentProgram,
} from '../interfaces';

type DepartmentPathContextProps = {
  departmentPaths: DepartmentPath[];
  setDepartmentPaths: Dispatch<SetStateAction<DepartmentPath[] | undefined>>;
  departmentRoles: DepartmentRole[];
  setDepartmentRoles: Dispatch<SetStateAction<DepartmentRole[] | undefined>>;
  filteredOutDepartmentPathIdsRef: React.MutableRefObject<string[]>;
  developmentPrograms: DevelopmentProgram[];
  setDevelopmentPrograms: Dispatch<
    SetStateAction<DevelopmentProgram[] | undefined>
  >;
  error: boolean;
};

export const DepartmentPathContext = createContext<DepartmentPathContextProps>({
  departmentPaths: undefined,
  setDepartmentPaths: () => {},
  departmentRoles: undefined,
  setDepartmentRoles: () => {},
  filteredOutDepartmentPathIdsRef: undefined,
  developmentPrograms: undefined,
  setDevelopmentPrograms: () => {},
  error: undefined,
});

export const DepartmentPathProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [departmentPaths, setDepartmentPaths] = useState<DepartmentPath[]>([]);
  const [departmentRoles, setDepartmentRoles] = useState<DepartmentRole[]>([]);
  const [developmentPrograms, setDevelopmentPrograms] = useState<
    DevelopmentProgram[]
  >([]);
  const [error, setError] = useState(false);
  const filteredOutDepartmentPathIdsRef = useRef([]);

  useEffect(() => {
    getDepartmentPaths()
      .then((paths) => {
        setError(false);
        setDepartmentPaths(paths as DepartmentPath[]);
      })
      .catch(() => {
        setError(true);
        setDepartmentPaths([]);
      });

    getDepartmentRoles()
      .then((roles) => {
        setError(false);
        setDepartmentRoles(roles as DepartmentRole[]);
      })
      .catch(() => {
        setError(true);
        setDepartmentRoles([]);
      });
  }, []);

  return (
    <DepartmentPathContext.Provider
      value={{
        departmentPaths,
        setDepartmentPaths,
        departmentRoles,
        setDepartmentRoles,
        filteredOutDepartmentPathIdsRef,
        developmentPrograms,
        setDevelopmentPrograms,
        error,
      }}
    >
      {children}
    </DepartmentPathContext.Provider>
  );
};
