import { useStoreApi } from 'reactflow';
import { PathEdge, PathNode } from '../../interfaces';
import { useContext } from 'react';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';

export function usePaths() {
  const store = useStoreApi();
  const { filteredOutDepartmentPathIdsRef } = useContext(DepartmentPathContext);
  const mainNodeId = 'main';
  const backgroundNodeId = 'background';
  const ignoreList = [mainNodeId, backgroundNodeId];

  const updatedNode = (node: PathNode, displayNode: boolean) => {
    if (!ignoreList.includes(node.id)) {
      return {
        ...node,
        data: {
          ...node.data,
          isDisabled: !displayNode,
        },
        style: {
          ...node.style,
          opacity: displayNode ? 1 : 0.25,
        },
      };
    }
    return node;
  };

  const updatedEdge = (edge: PathEdge, displayEdge: boolean) => {
    return {
      ...edge,
      style: {
        ...edge.style,
        opacity: displayEdge ? 1 : 0.25,
      },
    };
  };

  // given a path, display or fade out all nodes in that path
  const updateOpacityOfPathNodes = (pathId: string, displayNode: boolean) => {
    const storeState = store.getState();
    const storedNodes = storeState.getNodes() as PathNode[];

    const updatedNodes = storedNodes.map((node) => {
      if (node.data.parentDepartmentPathId === pathId) {
        return updatedNode(node, displayNode);
      }
      return node;
    });

    storeState.setNodes(updatedNodes);
  };

  // gives a list of roles, display or fade out all edges connected to those roles
  const updateOpacityOfPathEdges = (id: string, displayEdge: boolean) => {
    const storeState = store.getState();
    const storedEdges = storeState.edges as PathEdge[];

    const updatedEdges = storedEdges.map((edge) => {
      if (id === edge.departmentPathId) {
        return updatedEdge(edge, displayEdge);
      }
      return edge;
    });

    storeState.setEdges(updatedEdges);
  };

  // given the id of a selected node or path, display or fade out all other nodes
  const updateOpacityOfUnselectedNodes = (
    id: string,
    displayNodes: boolean,
    isDepartmentPath = false
  ) => {
    const storeState = store.getState();
    const storedNodes = storeState.getNodes() as PathNode[];

    const updatedNodes = storedNodes.map((node) => {
      // layer all other nodes underneath selected node
      if (node.id !== id) {
        node.style = {
          ...node.style,
          zIndex: displayNodes ? 2 : -1,
        };
      }

      if (node.id === mainNodeId) {
        node.style = {
          ...node.style,
          zIndex: -1,
        };
      }

      if (node.id === backgroundNodeId) {
        node.style = {
          ...node.style,
          zIndex: -2,
        };
      }

      const nodeIsFilteredOut =
        filteredOutDepartmentPathIdsRef.current.includes(
          node.data.parentDepartmentPathId
        );

      // if department path node was selected, fade out all nodes that are not part of the selected department path
      // else, fade out all nodes that are not the selected node
      const selectedId = isDepartmentPath
        ? node.data.parentDepartmentPathId
        : node.id;

      if (!nodeIsFilteredOut && selectedId !== id) {
        return updatedNode(node, displayNodes);
      }
      return node;
    });

    storeState.setNodes(updatedNodes);
  };

  // given the id of a selected node or path, display or fade out all other edges
  const updateOpacityOfUnselectedEdges = (
    id: string,
    displayEdges: boolean,
    isDepartmentPath = false
  ) => {
    const storeState = store.getState();
    const storedEdges = storeState.edges as PathEdge[];

    const updatedEdges = storedEdges.map((edge) => {
      const edgeIsFilteredOut =
        filteredOutDepartmentPathIdsRef.current.includes(edge.departmentPathId);

      // if department path node was selected, fade out all edges that are not part of the selected department path
      // else, fade out all edges
      const updateEdge = isDepartmentPath ? id !== edge.departmentPathId : true;

      if (!edgeIsFilteredOut && updateEdge) {
        return updatedEdge(edge, displayEdges);
      }
      return edge;
    });

    storeState.setEdges(updatedEdges);
  };

  return {
    updateOpacityOfPathNodes,
    updateOpacityOfPathEdges,
    updateOpacityOfUnselectedNodes,
    updateOpacityOfUnselectedEdges,
  };
}
