import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { DepartmentPathProvider } from './contexts/DepartmentPathContext';
import { ErrorBoundary } from './components/error/ErrorBoundary';

// css
import './index.css';

const root = createRoot(document.getElementById('app'));
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <DepartmentPathProvider>
        <App />
      </DepartmentPathProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
