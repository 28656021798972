import React, { useContext, useMemo } from 'react';
import ReactFlow, { Controls, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import { getAllPathEdges, getAllPathNodes } from '../../helpers';
import PathNode from './PathNode';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { ErrorMessage } from '../error/ErrorMessage';

export const DepartmentPathFlows: React.FC = () => {
  const ModalHeight = 350;
  const { departmentPaths, error } = useContext(DepartmentPathContext);
  const { setCenter } = useReactFlow();
  const nodeTypes = useMemo(() => ({ custom: PathNode }), []);
  const nodes = getAllPathNodes(departmentPaths);

  const focusNode = (node: any) => {
    const xPadding = 125;
    const yPadding = 100;
    const zoom = 1.5;
    const x = node.position.x + xPadding + node.width / 4;
    const y = node.position.y + yPadding + ModalHeight / 3.5;
    setCenter(x, y, { zoom, duration: 1000 });
  };

  if (error && !departmentPaths.length) {
    return <ErrorMessage />;
  }

  const focusModal = (node: any) => {
    if (node.id !== 'main' && node.id !== 'background') {
      setTimeout(() => {
        focusNode(node);
      }, 100);
    }
  };

  const edges = getAllPathEdges(nodes);
  return (
    <div className="text-blue w-full bg-white from-primary-dark via-primary-medium">
      <ReactFlow
        nodes={nodes}
        elementsSelectable={false}
        nodesConnectable={false}
        nodesDraggable={false}
        edges={edges}
        nodeTypes={nodeTypes}
        panOnScroll={true}
        edgesFocusable={true}
        zoomOnPinch={true}
        maxZoom={2}
        minZoom={0.5}
        panOnDrag={true}
        fitView
        fitViewOptions={{
          padding: 0.2,
        }}
        onNodeClick={(_, node) => focusModal(node)}
      >
        <Controls
          showFitView={false}
          showInteractive={false}
          position="bottom-right"
        />
      </ReactFlow>
    </div>
  );
};
