// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Competencies, Modules } from '../../interfaces';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

interface Props {
  title: JSX.Element;
  content?: Competencies | Modules;
  displayNumber?: boolean;
}

export const ListDetailSection: React.FC<Props> = ({
  title,
  content,
  displayNumber = false,
}) => {
  const fields = content?.fields;

  return content ? (
    <section className="relative grid auto-cols-fr grid-flow-col gap-36 px-20 text-white">
      <div>
        <h2 className="text-40 leading-normal">{title}</h2>
        {fields.description && (
          <p className="mt-4 text-22 leading-normal">{fields.description}</p>
        )}
        {fields.image && (
          <>
            <span className="competencies-image-background" />
            <img
              className="z-1 relative mt-6 w-96"
              src={`https:${fields.image.fields.file.url}`}
              alt={fields.image.fields.description || ''}
            />
          </>
        )}
      </div>

      <div className="mb-24 mt-4 flex flex-col space-y-8">
        {fields.list.map((item, index) => {
          const itemFields = item.fields;

          return (
            <div key={item.sys.id}>
              <div className="flex space-x-8">
                {itemFields.icon && (
                  <img
                    className="h-12 rounded-lg bg-white20 p-3"
                    src={`https:${itemFields.icon.fields.file.url}`}
                    alt=""
                  />
                )}
                {displayNumber && (
                  <p className="h-12 min-w-12 rounded-lg bg-white20 p-3 text-center text-20 font-semibold leading-normal text-white">
                    {index + 1}
                  </p>
                )}
                <div>
                  <h3 className="text-20 font-semibold">{itemFields.title}</h3>
                  {itemFields.format && (
                    <p className="text-14 font-semibold text-primary-bright">
                      {itemFields.format}
                    </p>
                  )}
                  <div className="content-description mt-2 text-14">
                    {parse(sanitizeHtml(marked(itemFields.description)))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  ) : null;
};
