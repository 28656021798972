import React from 'react';

export const TeamIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8825 9.75977H10.1625C7.6025 9.75977 5.5625 11.7998 5.5625 14.3598V16.7998C5.5625 19.3598 7.6025 21.3998 10.1625 21.3998H17.6825C18.1225 21.3998 18.4825 21.0398 18.4825 20.5998V14.3598C18.4825 11.8398 16.4025 9.75977 13.8825 9.75977ZM17.2825 20.1998H15.8825V14.9598C15.8825 14.3998 15.4425 13.9598 14.8825 13.9598C14.7625 13.9598 14.6825 14.0398 14.6825 14.1598V20.1998H10.1225C9.8425 20.1998 9.6025 20.1598 9.3225 20.1198V14.1598C9.3225 14.0398 9.2425 13.9598 9.1225 13.9598C8.5625 13.9598 8.1225 14.3998 8.1225 14.9598V19.5598C7.2825 18.9598 6.7225 17.9598 6.7225 16.7998V14.3598C6.7225 12.4798 8.2425 10.9598 10.1225 10.9598H13.8425C15.7225 10.9598 17.2425 12.4798 17.2425 14.3598V20.1998H17.2825Z"
      fill="#0050AA"
    />
    <path
      d="M11.9969 8.99961C13.7569 8.99961 15.1969 7.55961 15.1969 5.79961C15.1969 4.03961 13.7569 2.59961 11.9969 2.59961C10.2369 2.59961 8.79688 4.03961 8.79688 5.79961C8.79688 7.55961 10.2369 8.99961 11.9969 8.99961ZM11.9969 3.79961C13.1169 3.79961 13.9969 4.71961 13.9969 5.79961C13.9969 6.87961 13.0769 7.79961 11.9969 7.79961C10.8769 7.79961 9.99688 6.87961 9.99688 5.79961C9.99688 4.71961 10.8769 3.79961 11.9969 3.79961Z"
      fill="#0050AA"
    />
    <path
      d="M6.59852 8.59954C7.15852 8.59954 7.75852 8.39954 8.19852 8.07954C8.27852 7.99954 8.31852 7.87954 8.19852 7.75954L8.07852 7.63954C7.83852 7.31954 7.39852 7.19954 7.03852 7.31954C6.87852 7.35954 6.71852 7.39954 6.55852 7.39954C5.51852 7.39954 4.71852 6.43954 5.03852 5.35954C5.11852 5.07954 5.27852 4.79954 5.51852 4.63954C5.99852 4.23954 6.55852 4.15954 7.07852 4.27954C7.47852 4.39954 7.87852 4.27954 8.11852 3.95954L8.23852 3.79954C8.31852 3.71954 8.31852 3.55954 8.19852 3.51954C7.23852 2.83954 5.83852 2.79954 4.79852 3.67954C4.35852 4.03954 4.03852 4.55954 3.87852 5.11954C3.43852 6.95954 4.83852 8.59954 6.59852 8.59954Z"
      fill="#0050AA"
    />
    <path
      d="M18.4789 7C17.9989 7.4 17.4389 7.48 16.9189 7.32C16.5189 7.2 16.1189 7.32 15.8789 7.64L15.7589 7.8C15.6789 7.88 15.6789 8.04 15.7989 8.08C16.7589 8.76 18.1589 8.8 19.1989 7.96C19.6389 7.6 19.9989 7.08 20.1189 6.52C20.5589 4.68 19.1589 3 17.3989 3C16.8389 3 16.2389 3.2 15.7989 3.52C15.7189 3.6 15.6789 3.72 15.7989 3.84L15.9189 3.96C16.1589 4.28 16.5989 4.4 16.9589 4.28C17.1189 4.24 17.2789 4.2 17.4389 4.2C18.4789 4.2 19.2789 5.2 18.9589 6.24C18.8389 6.56 18.6789 6.8 18.4789 7Z"
      fill="#0050AA"
    />
    <path
      d="M6.40156 9.55938C6.40156 9.43937 6.32156 9.35938 6.20156 9.35938H5.20156C2.64156 9.35938 0.601562 11.3994 0.601562 13.9594V15.5994C0.601562 17.8394 2.36156 19.6794 4.60156 19.7994C4.72156 19.7994 4.80156 19.7194 4.80156 19.5994V19.4794C4.80156 19.0394 4.52156 18.6794 4.12156 18.5194V13.3194C4.12156 13.1994 4.04156 13.1194 3.92156 13.1194C3.36156 13.1194 2.92156 13.5594 2.92156 14.1194V17.9194C2.24156 17.3594 1.80156 16.5194 1.80156 15.5994V13.9594C1.80156 12.0794 3.32156 10.5594 5.20156 10.5594H5.40156C5.96156 10.5594 6.40156 10.1194 6.40156 9.55938Z"
      fill="#0050AA"
    />
    <path
      d="M18.8016 9.35938H17.8016C17.6816 9.35938 17.6016 9.43937 17.6016 9.55938C17.6016 10.1194 18.0416 10.5594 18.6016 10.5594H18.8016C20.6816 10.5594 22.2016 12.0794 22.2016 13.9594V18.5994H21.0816V14.1194C21.0816 13.5594 20.6416 13.1194 20.0816 13.1194C19.9616 13.1194 19.8816 13.1994 19.8816 13.3194V18.6394C19.4816 18.7994 19.2016 19.1594 19.2016 19.5994C19.2016 19.7194 19.2816 19.7994 19.4016 19.7994H22.6016C23.0416 19.7994 23.4016 19.4394 23.4016 18.9994V13.9594C23.4016 11.3994 21.3616 9.35938 18.8016 9.35938Z"
      fill="#0050AA"
    />
  </svg>
);
