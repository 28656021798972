import React from 'react';

export const DevelopmentProgramTab: React.FC = () => {
  return (
    <div className="tab-content-caption fade-in">
      <p className="text-white">
        Customized development programs to boost your career. Learn, grow, and
        excel with us!
      </p>
      <br />
      <p className="text-white">
        These programs are curated for specific roles and may not be available
        to all employees. Program eligibility information can be found on the
        specific Development Program page.
      </p>
    </div>
  );
};
