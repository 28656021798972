import React from 'react';
import { FAQ } from '../../interfaces';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

interface Props {
  faqs: FAQ[];
  isOpen?: boolean;
}

export const FAQSection: React.FC<Props> = ({ faqs, isOpen = false }) => {
  return faqs ? (
    <section className="px-20 py-24">
      <h2 className="mb-10 text-center text-40 leading-normal text-black">
        Frequently <b>Asked Questions</b>
      </h2>
      {faqs.map((faq, index) => (
        <details
          key={faq.sys.id}
          className="faq-details mb-2 rounded-2xl"
          open={isOpen}
        >
          <summary className="cursor-pointer p-6 text-16 font-bold leading-normal text-black">
            {index + 1}. {faq.fields.faqTitle}
          </summary>
          <p className="content-description px-6 pb-6 text-14 text-grey-text">
            {parse(sanitizeHtml(marked(faq.fields.faqDetails)))}
          </p>
        </details>
      ))}
    </section>
  ) : null;
};
