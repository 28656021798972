import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isRoleNode: boolean;
  description: string;
  id: string;
  color: string;
  image?: string;
  icon?: string;
  imageDescription?: string;
  departmentRoleFrom?: string;
  departmentRoleTo?: string;
  onClose: () => void;
}

export const InfoModal: React.FC<Props> = ({
  image,
  icon,
  isRoleNode,
  description,
  imageDescription,
  id,
  color,
  departmentRoleFrom,
  departmentRoleTo,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>();
  const modalWidth = 268;

  useEffect(() => {
    const modal = modalRef.current;
    const modalRect = modal?.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const elementIsOnLeft = modalRect.left < screenWidth - modalWidth;

    if (elementIsOnLeft && isRoleNode) {
      modal.style.left = '0';
    } else {
      modal.style.right = '0';
    }

    const handleOutsideClick = (event: Event) => {
      if (modal && !modal.contains(event.target as Node)) {
        onClose();
      }
    };

    const onCloseEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', onCloseEscape, false);
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
      document.removeEventListener('keydown', onCloseEscape, false);
    };
  }, [onClose]);

  return (
    <div
      ref={modalRef}
      className={`node-info-pop-up ${
        !isRoleNode ? 'node-info-path-pop-up' : ''
      }`}
    >
      <button type="button" onClick={() => onClose()} className="close-button">
        <span className="sr-only">Close</span>
      </button>
      {!isRoleNode && (
        <>
          {image ? (
            <img
              src={image}
              alt={imageDescription}
              className="department-path-image"
            />
          ) : null}
        </>
      )}
      <div className="p-6" id={id}>
        <img
          className="path-image"
          style={{ background: color }}
          src={icon}
          alt={'Office'}
        />
        {isRoleNode ? (
          <p className="mt-4 text-16 font-semibold text-black">
            <span className="font-normal">Grow From </span>
            {departmentRoleFrom}
            <span className="font-normal"> to </span>
            {departmentRoleTo}
          </p>
        ) : (
          <p className="mt-4 text-16 font-semibold text-black">
            <span>{departmentRoleFrom} Department</span>
          </p>
        )}
        <p
          className={`mt-2 text-12 text-grey-slate ${isRoleNode ? 'mb-4' : ''}`}
        >
          {description}
        </p>
        {isRoleNode && (
          <Link
            to={`${id}`}
            className="bg-secondary-bright px-4 py-2 text-14 font-semibold text-primary-medium"
          >
            See more details
          </Link>
        )}
      </div>
    </div>
  );
};
