import React, { useEffect, useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { TabPanel } from './tab-panel/TabPanel';
import { DepartmentPathFlows } from './department-paths/DepartmentPathFlows';
import { DevelopmentProgramCards } from './development-programs/DevelopmentProgramCards';

type Props = {
  defaultDisplayContent?: string;
};

export const Home: React.FC<Props> = ({ defaultDisplayContent }: Props) => {
  const [displayContent, setDisplayContent] = useState(
    defaultDisplayContent || 'Paths'
  );

  useEffect(() => {
    document.title = 'Career Pathing | Lidl US';
  }, []);

  return (
    <div className="container-main flex h-screen">
      <ReactFlowProvider>
        <TabPanel
          displayTabContent={displayContent}
          setDisplayTabContent={setDisplayContent}
        />
        {displayContent === 'Paths' && <DepartmentPathFlows />}
        {displayContent === 'DevelopmentPrograms' && (
          <DevelopmentProgramCards />
        )}
      </ReactFlowProvider>
    </div>
  );
};
