import React from 'react';

interface Props {
  name: string;
  isActive: boolean;
  handleOnClick: () => void;
}

export const FilterTabButton: React.FC<Props> = ({
  name,
  isActive,
  handleOnClick,
}) => {
  return (
    <button
      className={`tab-button ${isActive ? 'active' : ''}`}
      onClick={() => handleOnClick()}
    >
      <div>
        <span className="tab-button-text">{name}</span>
      </div>
    </button>
  );
};
