import React, { useContext, useEffect, useState } from 'react';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { DevelopmentProgram } from '../../interfaces';
import { getDevelopmentPrograms } from '../../apis/cms';
import { ProgramCard } from './ProgramCard';
import { ErrorMessage } from '../error/ErrorMessage';

export const DevelopmentProgramCards: React.FC = () => {
  const { developmentPrograms, setDevelopmentPrograms } = useContext(
    DepartmentPathContext
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!developmentPrograms.length) {
      getDevelopmentPrograms()
        .then((programs) => {
          setDevelopmentPrograms(programs as DevelopmentProgram[]);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, []);

  if (error) {
    return <ErrorMessage />;
  }

  return developmentPrograms ? (
    <div className="fade-in mx-auto grid w-full grid-cols-2 grid-rows-2 gap-4 p-8 py-16">
      {developmentPrograms.map((program) => (
        <ProgramCard key={program.sys.id} program={program} />
      ))}
    </div>
  ) : null;
};
