import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getContentfulEntry } from '../../apis/cms';
import { DepartmentPathContext } from '../../contexts/DepartmentPathContext';
import { DepartmentPath, DepartmentRole } from '../../interfaces';
import { getDepartmentPathForRole } from '../../helpers';
import { SimilarRoles } from './SimilarRoles';
import { TrainingDevelopment } from './TrainingAndDevelopment';
import {
  FAQSection,
  Hero,
  ListDetailSection,
  ResponsibilitiesSection,
  Testimonial,
} from '../sections';
import { ErrorPage } from '../error/ErrorPage';

export const RoleDetails: React.FC = () => {
  const { departmentRoleId } = useParams();
  const [departmentRole, setDepartmentRole] = useState<DepartmentRole>(null);
  const [departmentPath, setDepartmentPath] = useState<DepartmentPath>(null);
  const [fetchError, setFetchError] = useState(false);
  const { departmentRoles, departmentPaths, error } = useContext(
    DepartmentPathContext
  );

  useEffect(() => {
    const storedDepartmentRole = departmentRoles.find(
      (role) => role.sys.id === departmentRoleId
    );

    if (storedDepartmentRole) {
      setDepartmentRole(storedDepartmentRole);
    } else {
      getContentfulEntry(departmentRoleId)
        .then((entry) => setDepartmentRole(entry as DepartmentRole))
        .catch(() => {
          setDepartmentRole(null);
          setFetchError(true);
        });
    }
  }, [departmentRoleId]);

  useEffect(() => {
    if (departmentRole) {
      const path = getDepartmentPathForRole(
        departmentRole.sys.id,
        departmentPaths
      );
      setDepartmentPath(path);

      document.title =
        `${departmentRole.fields.departmentRoleTo} - ${
          path?.fields.departmentPathName || ''
        } | Lidl US` || 'Lidl US';
    }
  }, [departmentRole]);

  if (error || fetchError) {
    return <ErrorPage />;
  }

  return departmentRole ? (
    <div className="bg-grey-lightBackground">
      <div className="rounded-bl-60 bg-gradient-to-r from-primary-light via-primary-medium to-primary-dark">
        <div className="container-small">
          <Hero
            title={`Grow From ${departmentRole.fields.departmentRoleFrom} to ${departmentRole.fields.departmentRoleTo}`}
            subtitle={departmentPath?.fields.departmentPathName}
            description={departmentRole.fields.departmentRoleLongDescription}
            returnText="Return to map"
            returnPage="/"
            link={departmentRole.fields.jobListingLink}
            imageUrl={
              departmentRole.fields.departmentRoleImage?.fields.file.url
            }
            imageAlt={
              departmentRole.fields.departmentRoleImage?.fields.description
            }
          />
          <ResponsibilitiesSection
            title={
              <>
                <b>Essential</b> Functions
              </>
            }
            responsibilities={departmentRole.fields.essentialFunctions}
          />
          <ListDetailSection
            title={
              <>
                Core <b>Competencies</b>
              </>
            }
            content={departmentRole.fields.competencies}
          />
        </div>
      </div>
      <div className="container-small">
        <TrainingDevelopment
          trainingAndDevelopment={departmentRole.fields.trainingAndDevelopment}
        />
        <SimilarRoles roles={departmentRole.fields.similarRoles} />
      </div>
      <div className="bg-white">
        <div className="container-small">
          <Testimonial testimonial={departmentRole.fields.testimonial} />
          <FAQSection
            faqs={departmentRole.fields.faqs}
            isOpen={departmentRole.fields.openFaqs}
          />
        </div>
      </div>
    </div>
  ) : null;
};
