import React from 'react';
import { LidlIcon } from '../icons';

export const ErrorPage: React.FC = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-gradient-to-r from-primary-dark via-primary-medium to-primary-light ">
      <LidlIcon />
      <h1 className="mb-4 mt-5 text-40 leading-tight text-white">
        Oops! <b>Something went wrong.</b>
      </h1>
      <p className="mb-8 text-white">
        We&apos;re sorry, something went wrong. Please try again or use the link
        below.
      </p>
      <a
        href="/"
        className="bg-secondary-bright px-8 py-4 text-24 font-semibold text-primary-medium"
      >
        Return to Homepage
      </a>
    </div>
  );
};
