import { ForkedPathNode, PathEdge, PathNode } from '../interfaces';

const getNodesByPathId = (nodes: PathNode[]) => {
  const paths = nodes.reduce((acc, node) => {
    if (!acc[node.pathId]) {
      acc[node.pathId] = [];
    }
    acc[node.pathId].push(node);

    return acc;
  }, {} as { [key: string]: PathNode[] });

  return paths;
};

const getEdge = (
  node: PathNode,
  nextNode: PathNode | ForkedPathNode,
  sourceHandle?: string,
  targetHandle?: string
): PathEdge => {
  return {
    id: `${node.id}-${nextNode.id}`,
    source: node.id,
    target: nextNode.id,
    type: 'step',
    style: { stroke: '#003A7A', strokeWidth: 2 },
    departmentPathId: node.data.parentDepartmentPathId,
    sourceHandle,
    targetHandle,
  };
};

export const getAllPathEdges = (nodes: PathNode[]) => {
  const edges: PathEdge[] = [];
  const pathsWithNodes = getNodesByPathId(nodes);
  const pathIds = Object.keys(pathsWithNodes);

  pathIds.forEach((pathId) => {
    const pathNodes = pathsWithNodes[pathId];
    for (let i = 0; i < pathNodes.length; i++) {
      const node = pathNodes[i];
      const isLastNodeInPath = i === pathNodes.length - 1;
      if (isLastNodeInPath) {
        if (node.data.isForkedNode) {
          node.data.forkedNodes.forEach((forkedNode) => {
            const forkedEdge = getEdge(
              node,
              forkedNode,
              forkedNode.pathPosition
            );
            edges.push(forkedEdge);
          });
        }
      }
    }
  });

  return edges;
};
