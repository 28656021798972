import React from 'react';
import { Handle, Position } from 'reactflow';
import { PathNodeData } from '../../interfaces';

interface Props {
  data: PathNodeData;
}

export const PathNodeHandles: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.sourcePosition && (
        <Handle
          type="source"
          position={data.sourcePosition}
          className="invisible"
          style={{ right: 0 }}
        />
      )}
      {data.targetPosition && (
        <Handle
          type="target"
          position={data.targetPosition}
          className={data.isEndOfSkipPath ? 'dotAnimation' : 'invisible'}
          style={{
            left: data.isEndOfSkipPath
              ? data.sourcePosition === Position.Left
                ? 55
                : -20
              : 0,
          }}
        />
      )}
      {data.isForkedNode && (
        <>
          <Handle
            id="top"
            type="source"
            position={Position.Right}
            className="invisible"
            style={{ right: 0 }}
          />
          <Handle
            id="bottom"
            type="source"
            position={Position.Right}
            className="invisible"
            style={{ right: 0 }}
          />
        </>
      )}
      {data.isStartOfSkipPath && (
        <>
          <Handle
            id="top"
            type="source"
            position={Position.Top}
            className="invisible"
            style={{ top: 0 }}
          />
        </>
      )}
      {data.isEndOfSkipPath && (
        <>
          <Handle
            id="top"
            type="target"
            position={Position.Top}
            className="invisible"
            style={{ top: 0 }}
          />
        </>
      )}
    </>
  );
};
