import { Position } from 'reactflow';
import { DepartmentPath, PathNode } from '../interfaces';

const getSourceAndTargetPosition = (level: number) => {
  return level === 3 || level === 4 ? Position.Right : Position.Left;
};

const getNodePosition = (
  lastNodeWithSameLevel: PathNode,
  currentLevel: number
) => {
  const level1X = -90;
  const level2X = -50;
  const level3X = -250;
  const level4X = -350;
  const level1Y = 80;
  const level2Y = 249.2;
  const level3Y = 420;
  const level4Y = 249.2;
  const x = [level1X, level2X, level3X, level4X];
  const y = [level1Y, level2Y, level3Y, level4Y];
  const spacer = 70;

  const position = {
    x: lastNodeWithSameLevel
      ? currentLevel === 1 || currentLevel === 2
        ? lastNodeWithSameLevel.position.x + spacer
        : lastNodeWithSameLevel.position.x - spacer
      : x[currentLevel - 1],
    y: y[currentLevel - 1],
  };

  return position;
};

const getRoleNodes = (departmentPaths: DepartmentPath[]) => {
  const rolesNodes = departmentPaths.reduce((nodes: PathNode[], path) => {
    const { fields: pathFields } = path;
    const newPathNodes = pathFields.departmentPathRoles.map((role) => {
      const {
        fields: roleFields,
        sys: { id: sysId },
      } = role;
      const lastNodeWithSameLevel = nodes.findLast(
        (x) => x.data.level === role.fields.level
      );

      return {
        id: sysId,
        pathId: sysId,
        level: roleFields.level,
        position: getNodePosition(lastNodeWithSameLevel, roleFields.level),
        type: 'custom',
        data: {
          image: {
            description: roleFields.departmentRoleImage?.fields.description,
          },
          isEndOfSkipPath: false,
          isDisabled: false,
          level: roleFields.level,
          color: pathFields.departmentPathColor,
          description: roleFields.departmentRoleDescription,
          icon: pathFields.departmentPathIcon.fields.file.url,
          id: sysId,
          isRoleNode: true,
          departmentRoleFrom: roleFields.departmentRoleFrom,
          departmentRoleTo: roleFields.departmentRoleTo,
          parentDepartmentPathId: sysId,
          sourcePosition: getSourceAndTargetPosition(roleFields.level),
          targetPosition: getSourceAndTargetPosition(roleFields.level),
        },
        focusable: false,
        style: { opacity: 1, zIndex: 5 },
      };
    });
    return [...nodes, ...newPathNodes];
  }, []);

  rolesNodes.sort((a, b) => {
    return a.data.level - b.data.level;
  });

  for (let i = 1; i <= 4; i++) {
    const node = rolesNodes.findLast((x) => x.level === i);
    if (node) {
      node.data.isEndOfSkipPath = true;
    }
  }

  return rolesNodes;
};

const getMainNode = (nodesList: PathNode[]) => {
  const levelsPositions = ['top', 'right', 'bottom', 'left'];

  return {
    id: 'main',
    position: { x: -330, y: 120 },
    type: 'custom',
    data: {
      id: 'main',
      forkedNodes: nodesList.map((x) => {
        return {
          id: x.data.id,
          pathPosition: levelsPositions[x.data.level - 1],
        };
      }),
      isForkedNode: true,
      isDisabled: false,
      isRoleNode: false,
    },
    focusable: false,
    style: { opacity: 1, zIndex: -1 },
  };
};

const getBackgroundNode = () => {
  return {
    id: 'background',
    pathId: 'background',
    position: { x: -510, y: 120 },
    type: 'custom',
    data: {
      id: 'background',
      isDisabled: false,
      isRoleNode: false,
    },
    focusable: false,
    style: { opacity: 0.3, zIndex: -2 },
  };
};

export const getAllPathNodes = (departmentPaths: DepartmentPath[]) => {
  const nodesList: PathNode[] = getRoleNodes(departmentPaths);
  const mainNode = getMainNode(nodesList);
  const backgroundNode = getBackgroundNode();

  nodesList.push(mainNode);
  nodesList.push(backgroundNode);

  return nodesList;
};
