import React from 'react';

export const QuoteIcon: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 206">
        <path
          id="&#226;&#128;&#156;"
          d="M17.008 48.6373C14.0213 48.6373 11.4187 47.5707 9.2 45.4373C7.06667 43.304 6 39.72 6 34.6853C6 31.1013 6.512 27.9867 7.536 25.3413C8.56 22.6107 9.88267 20.3067 11.504 18.4293C13.2107 16.552 15.1733 15.016 17.392 13.8213C19.696 12.6267 22.0427 11.7307 24.432 11.1333C26.0533 10.7067 27.0773 11.304 27.504 12.9253L28.016 14.9733C28.528 16.5947 27.9733 17.704 26.352 18.3013C24.1333 19.1547 22.2133 20.392 20.592 22.0133C19.056 23.6347 18.0747 25.9813 17.648 29.0533C20.6347 29.224 22.9387 30.1627 24.56 31.8693C26.2667 33.576 27.12 35.9227 27.12 38.9093C27.12 41.9813 26.096 44.3707 24.048 46.0773C22.0853 47.784 19.7387 48.6373 17.008 48.6373ZM43.12 48.6373C40.1333 48.6373 37.5307 47.5707 35.312 45.4373C33.1787 43.304 32.112 39.72 32.112 34.6853C32.112 31.1013 32.624 27.9867 33.648 25.3413C34.672 22.6107 35.9947 20.3067 37.616 18.4293C39.3227 16.552 41.2853 15.016 43.504 13.8213C45.808 12.6267 48.1547 11.7307 50.544 11.1333C52.1653 10.7067 53.1893 11.304 53.616 12.9253L54.128 14.9733C54.64 16.5947 54.0853 17.704 52.464 18.3013C50.2453 19.1547 48.3253 20.392 46.704 22.0133C45.168 23.6347 44.1867 25.9813 43.76 29.0533C46.7467 29.224 49.0507 30.1627 50.672 31.8693C52.3787 33.576 53.232 35.9227 53.232 38.9093C53.232 41.9813 52.208 44.3707 50.16 46.0773C48.1973 47.784 45.8507 48.6373 43.12 48.6373Z"
          fill="#FFBF1F"
        />
      </g>
    </svg>
  );
};
